import {
  defineNuxtRouteMiddleware,
  navigateTo,
  useRequestEvent
} from 'nuxt/app'
import { storeToRefs } from 'pinia'
import { token } from '~/data/local'
import { useMyAuthStore } from '~/stores/auth.store'
import { UserRole } from '~/enums'

export default defineNuxtRouteMiddleware(async (to) => {
  // Skip middleware for prerendered pages
  const event = useRequestEvent()
  if (event?.node?.req?.url === '/') {
    return
  }

  // Check if we're on client-side
  if (import.meta.client) {
    // Public routes that don't require authentication
    const publicRoutes = ['/', '/login', '/register']
    if (publicRoutes.includes(to.path)) {
      const tokenPref = token()
      const tokenValue = await tokenPref.get()

      // If token exists, redirect based on role
      if (tokenValue) {
        const authStore = useMyAuthStore()
        await authStore.getProfileHandler()
        const { profile } = storeToRefs(authStore)

        if (profile.value?.roleId === UserRole.Admin) {
          return navigateTo('/admin/students')
        }
        return navigateTo('/dashboard')
      }
      return
    }

    // Check token for protected routes
    const tokenPref = token()
    const tokenValue = await tokenPref.get()

    if (!tokenValue) {
      return navigateTo('/login')
    }

    // Verify and load user profile if needed
    const authStore = useMyAuthStore()
    if (!authStore.getProfile) {
      await authStore.getProfileHandler()
    }

    const { profile } = storeToRefs(authStore)

    // Role-based route protection
    const adminRoutes = ['/admin', '/admin/students', '/admin/class']
    const isAdminRoute = adminRoutes.some((route) => to.path.startsWith(route))

    if (isAdminRoute && profile.value?.roleId !== UserRole.Admin) {
      return navigateTo('/dashboard')
    }

    if (
      to.path.startsWith('/dashboard') &&
      profile.value?.roleId === UserRole.Admin
    ) {
      return navigateTo('/admin/students')
    }
  }
})
